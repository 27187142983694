<template>
  <div class="set-edit">
    <gl-title :title="title" backIcon></gl-title>
    <gl-card>
      <div style="max-width:600px;padding-top:20px;">
      <el-form :model="formData" ref="form" label-width="120px" size="mini" v-loading="loading">
        <el-form-item prop="vipName" label="套餐名称" verify>
          <el-input v-model="formData.vipName" placeholder="请输入套餐名称" :maxlength="20"></el-input>
        </el-form-item>
        <el-form-item prop="vipLogo" label="VIP图片" verify :watch="formData.vipLogo">
          <gl-upload-images 
            :limit="1" 
            :type="2"
            v-model="formData.vipLogo" 
            ref="uploadImages" 
            :oldList="oldList" 
            :autoUpload="true">
          </gl-upload-images>
        </el-form-item>
        <el-form-item prop="vipPrice" label="套餐价格(元)" verify>
          <el-input v-model.number="formData.vipPrice" placeholder="请输入套餐价格" :maxlength="8"></el-input>
        </el-form-item>
        <el-form-item prop="content" label="说明" verify>
          <el-input type="textarea" :rows="5" :maxlength="5000" v-model.number="formData.content" placeholder="请输入说明"></el-input>
        </el-form-item>

        <el-form-item label="权益">
          <div style="height:32px;"></div>
          <el-form-item prop="discount" label="购物折扣" label-width="120px" verify>
            <el-input v-model="formData.discount" placeholder="请输入购物折扣" :maxlength="8">
              <span slot="suffix"> % </span>
            </el-input>
          </el-form-item>
          <el-form-item prop="giftType" label="赠品类型" verify>
            <gl-option v-model="formData.giftType" :list="giftType"></gl-option>
          </el-form-item>
          <el-form-item v-if="formData.giftType == '1'" prop="giftSum" label="赠送喜糖数量" verify>
            <el-input v-model="formData.giftSum" placeholder="请输入赠送喜糖数量" :maxlength="8">
              <span slot="suffix"> 颗 </span>
            </el-input>
          </el-form-item>
          <el-form-item prop="giftBalance" label="赠送消费金额" label-width="120px" verify>
            <el-input v-model="formData.giftBalance" placeholder="请输入赠送消费金额" :maxlength="8">
              <span slot="suffix"> 元 </span>
            </el-input>
          </el-form-item>
          <el-form-item prop="inviteRebate" label="喜粉消费返利" label-width="120px" verify>
            <el-input v-model.number="formData.inviteRebate" placeholder="请输入喜粉消费返利" :maxlength="8">
              <span slot="suffix"> % </span>
            </el-input>
          </el-form-item>
          <el-form-item prop="xiInviteBalance" label="邀请喜客奖励金额" label-width="150px" verify>
            <el-input v-model="formData.xiInviteBalance" placeholder="请输入邀请喜客奖励金额" :maxlength="8">
              <span slot="suffix"> 元 </span>
            </el-input>
          </el-form-item>
        </el-form-item>

        <el-form-item label="小喜">
          <div style="height:32px;"></div>
          <el-form-item prop="xiMoveUp" label="喜客升级小喜邀请喜客人数" label-width="200px" verify>
            <el-input v-model.number="formData.xiMoveUp" placeholder="请输入喜客升级小喜邀请喜客人数" :maxlength="8">
              <span slot="suffix"> 人 </span>
            </el-input>
          </el-form-item>
          <el-form-item prop="xxInviteBalance" label="邀请喜客奖励金额" label-width="200px" verify>
            <el-input v-model="formData.xxInviteBalance" placeholder="请输入邀请喜客奖励金额" :maxlength="8">
              <span slot="suffix"> 元 </span>
            </el-input>
          </el-form-item>
          <el-form-item prop="xxDivide" label="小喜分享商品分成" label-width="200px" verify>
            <el-input v-model.number="formData.xxDivide" placeholder="请输入小喜分享商品分成" :maxlength="8">
              <span slot="suffix"> 元 </span>
            </el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item label="大喜">
          <div style="height:32px;"></div>
          <el-form-item prop="xxMoveUp" label="小喜升级大喜邀请喜客人数" label-width="200px" verify>
            <el-input v-model.number="formData.xxMoveUp" placeholder="请输入小喜升级大喜邀请喜客人数" :maxlength="8">
              <span slot="suffix"> 人 </span>
            </el-input>
          </el-form-item>
          <el-form-item prop="dxDivide" label="大喜业绩分成" label-width="200px" verify>
            <el-input v-model.number="formData.dxDivide" placeholder="请输入大喜业绩分成" :maxlength="8">
              <span slot="suffix"> 元 </span>
            </el-input>
          </el-form-item>
        </el-form-item>

      </el-form> 
      <gl-flex>
        <el-button type="primary" @click="onSubmit" >确认</el-button>
        <el-button @click="handleClose">关闭</el-button>
      </gl-flex>
      </div>
    </gl-card>
  </div>
</template>

<script>
import { fetchVip, addVip, updateVip } from '@/api/vip'
export default {
  data(){
    return{
      title: '修改',
      vipId: '',
      formData: {},
      loading: false,
      oldList: [],
      giftType: [
        {name: '喜糖', value: '1'},
        {name: '实物', value: '2'},
      ],
    }
  },
  mounted(){
    this.vipId = this.$route.query.vipId
    this.title = this.vipId ? '修改' : '新增'
    this.vipId && this.getData()
  },
  methods: {
    async getData(){
      try{
        this.loading = true
        const res = await fetchVip(this.vipId)
        this.loading = false
        res.data.giftType = res.data.giftType.toString()
        this.formData = res.data
        this.oldList = [res.data.vipLogo]
      }catch(e){
        this.loading = false
      }
    },
    onSubmit(){
      this.$refs.form.validate(async valid => {
        if(!valid) return false
        this.loading = true
        const submitFun = this.vipId ? updateVip : addVip
        const res = await submitFun(this.formData)
        this.loading = false
        this.$message.success('操作成功')
        this.handleClose()
      })
    },
    handleClose(){
      this.$router.back()
    },
  },
}
</script>

<style>

</style>